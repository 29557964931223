import React, { useEffect, useState, useRef } from 'react';
import './Home.css';
import AOS from 'aos';
import { useNavigate } from "react-router-dom";  // Import useNavigate
import 'aos/dist/aos.css';
import title from './Images/space.jpg';
import tech from './Images/3d-rendering-blockchain-technology (1).jpg';
import mobile from './Images/1720.jpg';
import soft from './Images/1725.jpg';
import homeimg1 from'./Images/home1.jpg';
import homeimg2 from './Images/home2.jpg';
import homeimg3 from './Images/home3.jpg';
import homeimg4 from './Images/home4.jpg';
import homeimg5 from './Images/home5.jpg';
import homeimg6 from './Images/home6.jpg';

const Home = () => {
  const navigate = useNavigate(); 
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [goCount, setGoCount] = useState(0);
  const [finalMessage, setFinalMessage] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const testimonialRefs = useRef([]);
  const [activeLayer, setActiveLayer] = useState(0);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setGoCount((prevCount) => {
        if (prevCount >= 3) {
          clearInterval(interval);
          setFinalMessage(true);
          return prevCount;
        }
        return prevCount + 1;
      });
    }, 500);
   
   
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    testimonialRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      testimonialRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  const testimonials = [
    {
     
      name: 'Amelia Li',
      title: 'Co/Founder & CEO',
      image: 'path-to-image',
      quote: 'Vedanta Infotech exceeded our expectations with their exceptional web development services. Their team is professional, knowledgeable, and a pleasure to work with.'
      
    },
    {
     
      name: 'Ethan Anderson',
      title: 'Operations Manager',
      image: 'path-to-image',
      quote: 'Thanks to Vedanta Infotech, our CRM system is now more efficient and user-friendly. Their support team is always ready to assist us with any issues'
    },
    {
 
      name: 'Yamada Hiroto',
      title: 'CTO',
      image: 'path-to-image',
      quote: 'Vedanta InfoTech has been a game-changer for our business. Their team’s expertise in custom software development helped us streamline our operations and significantly improve productivity. From the initial consultation to project delivery, they were professional, responsive, and exceeded our expectations. The quality of their work is outstanding, and we look forward to collaborating with them again in the future.'
    },
    {
   
      name: 'Ava Rodriguez',
      title: 'Lead Software Engineer',
      image: 'path-to-image',
      quote: 'I am incredibly impressed with the level of service and technical knowledge Vedanta InfoTech brings to the table. They developed a highly scalable eCommerce platform for us that perfectly matches our business needs. The project was completed on time and within budget, and their ongoing support has been fantastic. I highly recommend Vedanta InfoTech for any web or mobile development projects.'
    }
  ];

  const doubledTestimonials = [...testimonials, ...testimonials];

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (!isPaused) {
        setHoveredCardIndex(index % testimonials.length);
        index++;
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [isPaused, testimonials.length]);
  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
};
  const steps = [
    {
      title: '01. Discovery',
      description: 'We begin by analyzing your Texas-based business\'s goals and project planning. For that, we name the problem and scope of your result, as well as chart out core functionality and milestones.'
    },
    {
      title: '02. Design and Development',
      description: 'Our team of experts designs a user-friendly interface and develops a robust backend system to meet your business needs.'
    },
    {
      title: '03. Full-Cycle Testing',
      description: 'We conduct thorough testing to ensure that all components work seamlessly together and that the final product is free from bugs.'
    },
    {
      title: '04. Implementation',
      description: 'Our team deploys the solution in a live environment, ensuring minimal disruption to your business operations.'
    },
    {
      title: '05. Maintenance',
      description: 'We provide ongoing support and maintenance to ensure your system remains up-to-date and continues to meet your business needs.'
    }
  ];

  const handleNextStep = () => {
    setActiveLayer((prev) => (prev + 1) % 5);
  };

  const handlePreviousStep = () => {
    setActiveLayer((prev) => (prev - 1 + 5) % 5);
  };

  const handleStepHover = (index) => {
    setActiveLayer(index);
  };
  const images = [homeimg1, homeimg2, homeimg3, homeimg4, homeimg5, homeimg6];


  return (
    <>
      <div className='home'>
        <div className='containerofhome-wrapper'>
          <div className='containerofhome'>
            <div>
            <div data-aos="fade-right"> <h1 className="heading1">Welcome to  <span>Vedanta Infotech</span> </h1>
            </div>
            <h2 className="home-heading">" Empowering Your Digital Journey "
 </h2>
</div>            <div>
            <div data-aos="fade-right">   <p className="home-para">Discover cutting-edge IT solutions tailored to propel your business forward. From web and app development to CRM systems and captivating animations, we have the expertise to transform your vision into reality.
</p></div>
            </div>
            <div className='top-buttons'>
             
              <button className='btn1'onClick={() => navigate('/contact')}>Get A Quote</button>
             
            </div>
          </div>
          <div className='slider'>
            <div className='slider-inner'>
              {images.map((src, index) => (
                <img key={index} src={src} alt={`img${index + 1}`} />
              ))}
              {images.map((src, index) => (
                <img key={index + images.length} src={src} alt={`img${index + 1}`} />
              ))}
            </div>
          </div>
        </div>
        <div className="eight-section">
          {/* <div className='threed'>  </div> */}
        <p className="eight-para">If You Want To Grow, Go Digital </p>
      
        </div>
    
        <div className="second-section">
          <div className="centered">
            <h1 className='trade'>Moving From Potential  <span className='you-trade'>To Performance</span></h1>
            <p className="paragraph">Explore the latest findings from the Vedanta Info-Tech AI Institute’s survey series tracking Generative AI adoption, successes, and challenges throughout 2024.</p>
          </div>
          {/* <div className='two-buttons'>
          
            <button className='btn4'>Get a quote</button>
          </div> */}
          <img src={title} alt="offer" className="title-image" />
        </div>
        {/* <div className='third-section'>
          <div className="digital">
            <span className="green">If you want to grow, </span>
            {finalMessage ? (
              <span className='green'>go digital.</span>
            ) : (
              <>
                <span id="initial-go" className="green">go</span>
                {Array.from({ length: goCount }, (_, i) => (
                  <div key={i} className="go" style={{ '--i': i }} >go</div>
                ))}
              </>
            )}
          </div>
        </div> */}
       
        <div className='fourth-section'>
        
          <h1 className='fourthheading'> Our <span>Services</span></h1>
          <div data-aos="fade-right">
            <div className="service-cards">
              <div className="service-card1">
                <div className="card-content">
                  <p>WEB DEVELOPMENT</p>
                  {/* <span>&rarr;</span> */}
                  <button className="explore1"onClick={() => navigate('/webdev')}>Explore</button>
                </div>
              </div>
              {/* <div className="service-card">
                <div className="card-content">
                  <p>E- COMMERCE</p>
                  <span>&rarr;</span>
                </div>
              </div> */}
              <div className="service-card2">
                <div className="card-content">
                  <p>MOBILE APPLICATION</p>
                  <button className="explore"onClick={() => navigate('/mobile')}>Explore</button>
                  {/* <span>&rarr;</span> */}
                </div>
              </div>
              <div className="service-card3">
                <div className="card-content">
                  <p>SOFTWARE DEVELOPMENT</p>
                  <button className="explore"onClick={() => navigate('/software')}>Explore</button>
                  {/* <span>&rarr;</span> */}
                </div>
              </div>
              <div className="service-card4">
                <div className="card-content">
              
                  <p>VIDEO ANIMATION</p>
                  <button className="explore"onClick={() => navigate('/animation')}>Explore</button>
                  {/* <span>&rarr;</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fifth-section'>
  <div className='top-section'>
    <h1 className="roadmap-heading">Our Professional  Development Process</h1>
    {/* <button className='btn5'>Book Our Consultation</button> */}
  </div>
  <div className="process-section">
    <div className="text-section">
      <h2 className="step-number">{steps[activeLayer].title.split(' ')[0]}</h2> {/* Display only the number */}
      <h3 className="titleofsectionthree">{steps[activeLayer].title.split('. ')[1]}</h3>
      <p className="description">
        {steps[activeLayer].description}
      </p>
      <div className='top-buttons'>
        <button className='btn7' onClick={handlePreviousStep}>Previous</button>
        <button className='btn6' onClick={handleNextStep}>Next</button>
      </div>
    </div>
    <div className="graphic-section">
      <div className="steps">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${activeLayer === index ? 'active' : ''}`}
            onMouseEnter={() => handleStepHover(index)}
          >
            <h4>{step.title}</h4>
            <div className={`our-process-slider__figure-svg ${activeLayer === index ? 'active' : ''}`}>
              <svg width="160" height="60" gap="60" color="purple" viewBox="0 0 260 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.95">
                  <path className="svg-fill" d="M260 43.2L130 86.3L0 43.2L130 0L260 43.2Z"></path>
                  <path className="svg-fill" d="M0 43.2002V55.0002L130 98.1002V86.3002L0 43.2002Z"></path>
                  <path className="svg-fill" d="M260 43.2002V55.0002L130 98.1002V86.3002L260 43.2002Z"></path>
                </g>
              </svg>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</div>
<div class="sixth-section">
<div data-aos="fade-right">  <h1 class="blogs-heading">Read Our Exciting<span> Blogs</span></h1></div>
    <p class="blogs-description">
    Stay updated with the latest trends, tips, and insights from the world of Information Technology. 

    </p>
    <div class="blog-card-container">
      <div class="large-card">
        <img src={tech}alt="Large Card" class="large-card-image" />
        <div className="headbu">
        <h2 class="large-card-heading">The Top Web Development Blogs You Should Bookmark</h2>
        <div className="btn-blog1">
          <button className="btn111"onClick={() => navigate('/blog/webdevelopment')}>Read More</button></div></div>
        <p class="large-card-description">
        Now, in this segment, we unveil the top web development blogs deserving of a coveted bookmark in your browser.
        </p>
      </div>
      <div class="small-cards">
        <div class="small-card">
          <img src={mobile} alt="Small Card 1" class="small-card-image" />
          <div className="headbu">
          <h3 class="small-card-heading">Mobile App</h3>
          <div className="btn-blog">
          <button className="btn112"onClick={() => navigate('/blog/mobileapp')}>Read More</button></div></div>
          {/* <p class="small-card-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p> */}
        </div>
        <div class="small-card">
          <img src={soft} alt="Small Card 2" class="small-card-image" />
          <div className="headbu">
          <h3 class="small-card-heading">E-Commerce</h3>
          <div className="btn-blog">
          <button className="btn112"onClick={() => navigate('/blog/ecommerce')}>Read More</button></div></div>
          {/* <p class="small-card-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p> */}
        </div>
      </div>
    </div>
  </div>
        <div className={`seventh-section ${isPaused ? 'paused' : ''}`}>
          <section className="testimonials">
            
            <div className="testimonials-header">
            <div data-aos="fade-right"><h2>Read from our satisfied Clients</h2></div>
            <div data-aos="fade-right">     <p>Our clients are at the core of everything we do and their satisfaction is our success.</p></div>
            </div>
            <div className="testimonials-list">
              <div className="scrolling-wrapper">
                {doubledTestimonials.map((testimonial, index) => (
                  <div
                    className="testimonial-card"
                    key={index}
                    ref={(el) => (testimonialRefs.current[index] = el)}
                    onMouseEnter={() => setIsPaused(true)} 
                    onMouseLeave={() => setIsPaused(false)} 
                  >
                    <div className="testimonial-header">
                      <div className="testimonial-company">{testimonial.company}</div>
                      <div className="testimonial-name">{testimonial.name}</div>
                      <div className="testimonial-title">{testimonial.title}</div>
                    </div>
                    <div className="testimonial-quote">{testimonial.quote}</div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
       
      </div>
    </>
  );
}

export default Home;
