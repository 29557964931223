import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import  React from "react";
import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home';
import Webdev from './components/services/Webdev';
import Footer from './components/footer/Footer';
import Ecommerce from './components/services/Ecommerce';
import MobileApp from './components/services/MobileApp';
import Software from './components/services/Software';
import Animation from './components/services/Animation';
import About from './components/about/About';
import Carrier from './components/carrier/Carrier';
import Apply from './components/carrier/Apply';
import Job from'./components/carrier/Job';
import { HelmetProvider } from 'react-helmet-async';
import BlogPageTemplate from "./components/Blogs/BlogPageTemplate";
import BlogSection from "./components/Blogs/BlogSection";
import Contact from './components/contact/Contact';
import ApplicationForm from './components/sample';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import Details from './components/services/products/Ani-Details';

import EcomDetails from './components/services/products/ECom-Details';

import MobileDetails from './components/services/products/Mobile-Details';

import SoftwareDetails from './components/services/products/Software-Details';

import WebdevDetails from './components/services/products/Webdev-Details';

import TC from './components/terms and conditions/T&C';
import PP from './components/privarcypolicy/PP';

function App() {
    // const [, setisLoaded] = useState(false);
  
    // useEffect(() => {
    //   setTimeout(() => {
    //     setisLoaded(true);
    //     window.scrollTo({
    //       top: 0,
    //       behavior: "smooth",
    //     });
    //   }, 3000);
    // }, []);
    return (
        <HelmetProvider>
            <Router>
                <Navbar />
                <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Bounce"  // Corrected this line
      />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/career" element={<Carrier />} />
                    <Route path="/apply" element={<Apply />} />
                    <Route path="/ani-details" element={<Details />} />
                   
                    <Route path="/ecom-details" element={<EcomDetails />} />
                    
                    <Route path="/mobile-details" element={<MobileDetails />} />
               
                    <Route path="/software-details" element={<SoftwareDetails />} />
                 
                    <Route path="/webdev-details" element={<WebdevDetails />} />
                    <Route path="/job" element={<Job />} />
                    <Route path="/blog/:key" element={<BlogPageTemplate />} />
                    <Route path="/blogs" element={<BlogSection />} />
                    <Route path="/contact" element={ <Contact/>} />
                    <Route path="/webdev" element={<Webdev />} />
                    <Route path="/ecom" element={<Ecommerce />} />
                    <Route path="/mobile" element={<MobileApp />} />
                    <Route path="/software" element={<Software />} />
                    <Route path="/animation" element={<Animation />} />
                    <Route path="/apply2" element={<ApplicationForm/>}/>
                    <Route path="/tc" element={<TC/>}/>
                    <Route path="/pp" element={<PP/>}/>
                </Routes>
                <Footer />
            </Router>
        </HelmetProvider>
    );
};

export default App;
