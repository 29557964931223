import React from 'react';
import './Footer.css';
import logoImage from './Images/vedanta.png'; 

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3> | Contact Info</h3>
          <ul>
            <li>Let's get connected</li>
            <li className="horizontalline">Block No.132, 2nd Floor, Shriram Tower, Next to N.I.T Kingsway Civil Line, Sadar, Nagpur-440001</li>
      
            <li className="verticalline" >infoveda24@gmail.com                    </li>
            <li>(+91)-9067488273</li>
          </ul>
        </div>
        <div className="footer-section">
          <h3> | Policy Pages</h3>
          <ul>
            <a href='/tc' className='anchor'>Terms and Conditions</a>
            <br/>   
            <a href='/pp' className='anchor'>Privacy Policy</a>
  
          
          </ul>
        </div>
      
        <div className="footer-social">
         
        <div className="social">
          <a href=" https://www.facebook.com/profile.php?id=61563767378541
" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
          <a href=" https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>

          <a href=" https://www.instagram.com/vedanta_info_tech/?__pwa=1
" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Vedanta Infotech. All rights reserved.  </p>
        {/* <ul>
          <li><a href="#">Privacy Policy</a></li>
          <li><a href="#">Terms & Conditions</a></li>
          <li><a href="#">Refund Policy</a></li>
        </ul> */}
      </div>
    </div>
  );
};

export default Footer;
