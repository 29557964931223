import React, { useEffect, useState, useRef, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import './Navbar.css';
import logoImage from './Images/vedanta.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import LoginModal from './LoginModal';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestion, setSuggestion] = useState('');
    const searchInputRef = useRef(null);
    const navigate = useNavigate();
    const { user, logoutUser } = useContext(UserContext);

    // Search index mapping keywords to URLs
    const searchIndex = {
        "home": "/",
        "about": "/about",
        "career": "/career",
        "blogs": "/blogs",
        "contact": "/contact",
        "web development": "/webdev",
        "e-commerce": "/ecom",
        "mobile app": "/mobile",
        "software": "/software",
        "animations": "/animation"
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTotal = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrollProgress = (document.documentElement.scrollTop / scrollTotal) * 100;
            document.getElementById("progress-bar").style.width = scrollProgress + "%";
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (isSearchVisible) {
            searchInputRef.current.focus();
        }
    }, [isSearchVisible]);

    useEffect(() => {
        // Update the suggestion based on the search query
        if (searchQuery) {
            const results = Object.keys(searchIndex).filter(item =>
                item.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setSuggestion(results[0] || ''); // Set the first matched result or an empty string
        } else {
            setSuggestion('');
        }
    }, [searchQuery]);

    const toggleSearch = () => {
        setIsSearchVisible(!isSearchVisible);
        setSearchQuery(''); // Reset search query when toggling
        setSuggestion('');
    };

    const closeSearch = () => {
        setIsSearchVisible(false);
        setSearchQuery('');
        setSuggestion('');
    };

    const toggleLoginModal = () => {
        setIsLoginModalOpen(!isLoginModalOpen);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchKeyDown = (event) => {
        if (event.key === 'Enter' && suggestion) {
            navigate(searchIndex[suggestion.toLowerCase()]); // Redirect to the URL of the suggestion
            closeSearch();
        }
    };

    return (
        <div className='navbar'>
            <div className="top-navbar">
               
                <div className="logo">
                   <a href='/'> <img src={logoImage} alt="MiMag Technologies Logo" className="logo-image" />
                   
                   </a>  </div>
                <div className={`menu-top ${isMenuOpen ? 'active' : ''}`}>
                    <a href="/">Home</a>
                    <a href="/about">About</a>
                    <a href="/career">Career</a>
                    <a href="/blogs">Blogs</a>
                    <a href="/contact">Contact Us</a>
                </div>
                
                <div className="search-navbar">
                    
                    {!isSearchVisible && (
                        <div className="search" onClick={toggleSearch}>
                            <FontAwesomeIcon icon={faSearch} className="search-icon-border" />
                        </div>
                    )}
                    <div className={`search-bar-container ${isSearchVisible ? 'visible' : ''}`}>
                        {isSearchVisible && (
                            <div className="search-bar">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    ref={searchInputRef}
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                    onKeyDown={handleSearchKeyDown}
                                    list="suggestions" // Add datalist for auto-complete
                                />
                                <datalist id="suggestions">
                                    {suggestion && <option value={suggestion} />} {/* Auto-complete suggestion */}
                                </datalist>
                                <div className="close-search" onClick={closeSearch}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            </div>
                        )}
                    </div>
                 
                    <FontAwesomeIcon icon={faUser} onClick={toggleLoginModal} />
                    {/* <div className='userName'>
                        {user ? (
                            <p className="user">{user.name}</p>
                        ) : (
                            <span></span>
                        )}
                    </div> */}
                    <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="hamburger" onClick={toggleMenu} />
                </div>
            </div>
            <div className="bottom-navbar">
                <div className="offerings">Offerings</div>
                <div className="menu">
                    <a href="webdev">Web Development</a>
                    <a href="ecom">E-Commerce</a>
                    <a href="mobile">Mobile App</a>
                    <a href="software">Softwares</a>
                    <a href="animation">Animations</a>
                </div>
            </div>
            <div className="progress-container">
                <div className="progress-bar" id="progress-bar"></div>
            </div>
            {isLoginModalOpen && <LoginModal isOpen={isLoginModalOpen} onClose={toggleLoginModal} />}
        </div>
    );
};

export default Navbar;
