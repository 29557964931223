import React, { useState,useEffect } from 'react';
import { useNavigate } from "react-router-dom";  // Import useNavigate
import './Apply.css';
import AOS from 'aos';
const Apply =()=>{
    useEffect(() => {
        AOS.init({
          duration: 1200,
        });
      }, []);
    const navigate = useNavigate(); 
    const [filters, setFilters] = useState({
        keyword: '',
        state: '',
        city: '',
        jobCategory: '',
        hireType: ''
    });

    const jobs = [
        {
            title: "Audit & Assurance Senior Assistant Full Time, Mobile App",
            location: "Nagpur,Maharashtra",
            company: "Vedanta InfoTech ",
        },
        {
            title: "Audit & Assurance Staff Intern - Summer 2026,E-Commerce",
            location: "Nagpur,Maharashtra",
            company: "Vedanta InfoTech ",
        },
        {
            title: "Manager, Web Development",
            location: "Nagpur,Maharashtra",
            company: "Vedanta InfoTech ",
        },
        {
            title: "Audit & Assurance Staff Intern - Summer 2026,Animations",
            location: "Nagpur,Maharashtra",
            company: "Vedanta InfoTech ",
        },
        {
            title: "Manager, Softwares",
            location: "Nagpur,Maharashtra",
            company: "Vedanta InfoTech ",
        },
        {
            title: "Audit & Assurance Staff Intern - Summer 2026,Animations",
            location: "Nagpur,Maharashtra",
            company: "Vedanta InfoTech ",
        },
        // Add more job listings here
    ];

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const filterJobs = () => {
        return jobs.filter(job => {
            const matchesKeyword = job.title.toLowerCase().includes(filters.keyword.toLowerCase());
            const matchesState = filters.state ? job.location.includes(filters.state) : true;
            const matchesCity = filters.city ? job.location.includes(filters.city) : true;
            const matchesCategory = filters.jobCategory ? job.title.includes(filters.jobCategory) : true;
            const matchesHireType = filters.hireType ? job.title.includes(filters.hireType) : true;

            return matchesKeyword && matchesState && matchesCity && matchesCategory && matchesHireType;
        });
    };

    const filteredJobs = filterJobs();

    return (
        <><div className="app-main">
            <div className="app-sec1">
                <div className="app-content">
                <div data-aos="fade-right">
                    <h1 className="app-head">Welcome to careers at <span>Vedanta Info-Tech</span></h1>
                    <h2 className='app-head2'>What impact will you make?</h2>
                    <p className='app-para'>At Vedanta Info-Tech, you'll have the opportunity to enhance your experience or launch your career working side-by-side with talented professionals and thought leaders. Search and apply for a job today.</p>
                </div></div></div>
                <div data-aos="fade-left">
            <div className="app-sec2"></div></div>
        </div>
        <div className="app">

                <div className="filter">
                    <input className="filter-input"
                        type="text"
                        name="keyword"
                        placeholder="Search by keyword"
                        value={filters.keyword}
                        onChange={handleFilterChange} />
                        <select name="state" value={filters.state} onChange={handleFilterChange}>
                        <option value="">State</option>
                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                        <option value="Delhi (National Capital Territory)">Delhi (National Capital Territory)</option>
                        <option value="Goa">Goa</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jammu & Kashmir">Jammu & Kashmir</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Ladakh">Ladakh</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>


                        {/* Add more state options */}
                    </select>
                    <select name="city" value={filters.city} onChange={handleFilterChange} placeholder="City">
                        <option value="">City</option>
                        <option value="Nagpur">Nagpur</option>
                    

                        {/* Add more city options */}
                    </select>
                    <select name="jobCategory" value={filters.jobCategory} onChange={handleFilterChange}>
                        <option value="">Job Category</option>
                        <option value="Audit">Audit</option>
                        <option value="Assurance">Assurance</option>
                        {/* Add more categories */}
                    </select>
                    <select name="hireType" value={filters.hireType} onChange={handleFilterChange}>
                        <option value="">Hire Type</option>
                        <option value="Intern">Intern</option>
                        <option value="Full Time">Full Time</option>
                        {/* Add more hire types */}
                    </select>
                </div>
                <div className="job-list">
                    {filteredJobs.map((job, index) => (
                          <div data-aos="fade-up">
                        <div key={index} className="job-item">
                            <h1 className="job-sec2-head">{job.title}</h1>
                            <p>{job.location}</p>
                            <p>{job.company}</p>
                            <button className="car-apply-button" onClick={() => navigate('/job')}>Apply Now</button>
                        </div></div>
                    ))}
                </div>
            </div></>
    );
};



export default Apply
