import React from 'react';
import './PP.css';
const PP=()=> {
  return (
    <div className='pp-contain'>
      <h1 className='tc-h1'>Privarcy Policy</h1>
      <p>At Vedanta InfoTech, accessible from www.vedantatech.in, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that are collected and recorded by www.vedantatech.in and how we use it.If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at infoveda24@gmail.com
</p>
<h3 >Log Files</h3>
<p>www.vedantatech.in follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and are a part of hosting services’ analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is to analyze trends, administer the site, track users’ movement on the website, and gather demographic information.
</p>
    
    <h3>Cookies And Web Beacons</h3>
    <p>Like any other website, www.vedantatech.in uses cookies. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.</p>
   <h3>Google DoubleClick DART Cookie</h3>
   <p>Google is one of the third-party Vendors on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to<a> www.vedantatech.in </a>and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – https://policies.google.com/technologies/ads
</p>
<h3>Privacy Policies</h3>
<p>You may consult this list to find the Privacy Policy for each of the advertising partners of mimagtech.com Third-party ad servers or ad networks, use technologies like cookies, JavaScript, or Web Beacons used in their respective advertisements and links that appear on www.vedantatech.in. These are sent directly to users’ browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.Note that www.vedantatech.in has no access to or control over these cookies used by third-party advertisers.</p>
   <h3>Third-Party Privacy Policies</h3>
   <p>www.vedantatech.in‘s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of specific options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found on the browsers’ respective websites. What Are Cookies?
</p>
<h3>Children’s Information</h3>
<p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.mimagtech.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
</p>
<h3>Online Privacy Policy Only
</h3>
<p>This Privacy Policy (created with the GDPR Privacy Policy Generator) applies only to our online activities and is valid for visitors to our website with regards to the information that they shared or collect on mimagtech.com. This policy is not applicable to any information collected offline or via channels other than this website.
This privacy policy has been compiled to better serve those who are concerned with how their ‘Personally Identifiable Information (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
</p>
   <h2>What personal information do we collect from the people that visit our website?
</h2>
   <p>When registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, and other details to help you with your experience.
</p>
<h2>When do we collect information?
</h2>
<p>We collect information from you when you register on our site, subscribe to a newsletter, respond to a survey, fill out a form, or enter information on our site.
</p>
<h2>How do we use your information?</h2>
<p>We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or use certain other site features in the following ways:
</p>
<ul>
    <li> To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
    <li>To improve our website in order to better serve you.</li>
    <li>To allow us to better service you in responding to your customer service requests.</li>
    <li>To administer a contest, promotion, survey, or other site feature.</li>
    <li>To follow up with them after correspondence (live chat, email, or phone inquiries)
</li>
</ul>
<h2>How do we protect your information?
</h2>
<p>Our website is scanned regularly for security holes and known vulnerabilities to make your visit to our site as safe as possible.
</p>
   
   
   
    </div>
  )
}

export default PP