import React, { useEffect, useState } from 'react'; 
import './About.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { useNavigate } from "react-router-dom";  // Import useNavigate
import Img1 from './Images/img1.jpg';
import Img2 from './Images/img2.jpg'
import Img3 from './Images/img3.jpg'
import Img4 from './Images/img4.jpg'
import Img5 from './Images/img5.jpg'
import Img6 from './Images/img6.jpg'

const About = () => {
  const navigate = useNavigate(); 
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  // Core values
  const values = [
    {
      title: "Collaboration",
      description:
        "Collaboration is the process of two or more people or organizations working together to complete a task or achieve a goal.",
      icon: "⚡", // You can replace this with an icon component if needed
    },
    {
      title: "Transparency",
      description:
        "Transparency, as used in science, is operating in such a way that it is easy for others to see what actions are performed.",
      icon: "🔍",
    },
    {
      title: "Trust",
      description:
        "Trust will help us foster a positive and productive environment that delivers value to our users and customers.",
      icon: "🔄",
    },
    {
      title: "Integrity",
      description:
        "Integrity is the quality of being honest and having strong moral principles, ensuring consistency in actions and values.",
      icon: "📏",
    },
  ];

  // Images array
  const images = [
    {
      src: Img6, // Use imported image
      title: 'Innovative Solutions'
    },
    {
      src: Img1,
      title: 'Scalable Platforms'
    },
    {
      src: Img2,
      title: 'Customer-Centric Design'
    },
    {
      src: Img3,
      title: 'AI-Driven Analytics'
    },
    {
      src: Img4,
      title: 'Sustainability & Eco-Friendly Tech'
    },
    {
      src: Img5,
      title: '24/7 Support'
    },
  ];

  return (
    <div className="about">
      <div className="about-sec1">
        <div className="about-headpara">
          <div data-aos="fade-right">
            <h1 className="about-heading-sec1">Our <span >Company</span></h1>
            <p className="about-para-sec1">
            At Vedanta Infotech, we believe in harnessing technology to create impactful digital solutions. Since our inception, we have dedicated ourselves to delivering innovative and customized IT services that meet the unique needs of our clients. Our team of experts is committed to excellence, ensuring that every project we undertake is a success.

            </p>
          </div>
        </div>
      </div>

      <div className="about-sec2">
        <div className="vertical">
          <div data-aos="fade-right">
            <h1 className="vertical-heading">How we work with our <span>Clients</span></h1>
            <div className='vertical-buttons'>
              <button className='about-btn1'onClick={() => navigate('/contact')}>Explore More</button>
            </div>
          </div>
        </div>
        <div className="horizon">
          <div data-aos="fade-left">
            <p className="horizon-para">
            Explore our portfolio to see how we've helped businesses achieve their digital goals. From dynamic websites to powerful applications, our projects showcase our expertise and commitment to quality.

            </p>
          </div>
        </div>
      </div>

      <div className="about-sec3">
        <div className="about-twocomp">
          <div data-aos="fade-right">
            <div className="about-img"></div>
          </div>
          <div className="about-desc">
            <div data-aos="fade-left">
              <h1 className="about-desc-heading">Dream Until <span>Dreams Come True</span></h1>
              <p className='about-desc-para'>
                When you need us to improve your business, then come with us to help your
                then come have reach it, you just sit and feel that goal
                then come have reach it, you just sit and feel that goal
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="about-sec4">
        <div className="core-values">
          <div data-aos="fade-up">
            <h1 className="core-values-heading1">Our Core <span>Values</span></h1>
            <p className="about-sec4-par">
              Our values shape the culture of our organization and define the
              character of our company.
            </p>
          </div>
          <div className="values-container">
            {values.map((value, index) => (
              <div className="value-card" key={index}>
                <div className="value-icon">{value.icon}</div>
                <h3>{value.title}</h3>
                <p>{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="about-sec5">
        <Carousell images={images} />
      </div>
    </div>
  );
}

const Carousell = ({ images }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="carousel-container">
        <div data-aos="fade-up">
      <h1 className="core-values-heading">Features and <span>Mission</span> </h1></div>
      <div className="carousel-images">
        
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-item ${hoveredIndex === index ? 'hovered' : ''}`}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <img
              src={image.src}
              alt={`Slide ${index + 1}`}
              className="carousel-image"
            />
            {hoveredIndex === index && (
              <div className="carousel-caption">
                <p>{image.title}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default About;
