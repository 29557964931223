import React,{useState,useEffect} from 'react';
import 'axios';
import './Contact.css';
import Loader from '../common/loader';
import AOS from 'aos';
import { toast } from 'react-toastify';
import axios from 'axios';
const Contact=()=> {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNumber: '',
        message: '',
        inquiry: 'General Inquiry',
      });
      const [isLoading,setLoading]=useState(false);
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = async(e) => {
        e.preventDefault();
        if (
          formData.name.trim() === "" || 
          formData.email.trim() === "" || 
          formData.contactNumber.trim() === "" || 
          formData.message.trim() === ""
        ) {
          toast.warn("Please fill all the fields");
          return;
        }
     setLoading (true) 
      try{
      
        await axios.post(`${process.env.REACT_APP_API_URL}/contactus`, formData);
       toast.success("Form submitted successfully");
      }
      catch(error){
       toast.error("something went wrong");
       console.log(error);
      }
      finally{
        setLoading (false) ;
      }
      
     

  };
  return (
    <div className="contact-hero">
      {isLoading&&<Loader/>}
    <div className="contact-sec1">
    <div className="flex">
    <div data-aos="fade-right">
      <h1 className="contact-head"><span>Contact Us</span></h1></div>
      {/* <div className="contact-para1">
        <p className='contac-para'>
      Have a project in mind or need IT support? Contact us today to discuss how we can help you achieve your business goals.
      </p></div>*/}</div> 
      <div className="contact-cards">    
        <div className="contact-card1">
        <div data-aos="fade-up">
        <h3 className="detail-head">Get in touch!</h3>
            <p className="detail-para">
           Address: Block No.132, 2nd Floor, Shriram Tower, Next to N.I.T Kingsway Civil Line, Sadar, Nagpur-440001
            </p>
            <p className="detail-para">Phone:(+91)-9067488273
          </p> 
          <p className="detail-para">Email:infoveda24@gmail.com
          </p> 
        </div></div>
        
      </div>
     
    
    </div>
   
    <div className="contact-sec2">

    {/* <h1 className="contact-sec2-head"> Write to<span> Us</span></h1> */}
    <div className="contact-form-container">
   
      <form onSubmit={handleSubmit} className="contact-form">
      <div data-aos="fade-up">
      <label htmlFor="name" className="contatct-label">Name</label>
        <input  className='contact-input'
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
     
          required

        />
         
         <label htmlFor="email"className="contatct-label">Email</label>
        <input className='contact-input'
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
          <label htmlFor="contactNumber"className="contatct-label">Contact Number</label>
        <input className='contact-input'
          type="text"
          name="contactNumber"
          placeholder="Contact Number"
          value={formData.contactNumber}
          onChange={handleChange}
          required
        />
         <label htmlFor="inquiry"className="contatct-label">Inquiry Type</label>
        <select
          name="inquiry"
          value={formData.inquiry}
          onChange={handleChange}
        >
          <option value="General Inquiry">General Inquiry</option>
          {/* Add more options if needed */}
        </select>
        <label htmlFor="message"className="contatct-label">Message</label>
        <textarea
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        {/* <div className="captcha">
          <input type="checkbox" required /> I'm not a robot
        </div> */}
        <button type="submit" className='contact-button'>Submit</button>
        </div>
      </form>
    </div>
    </div></div>
    
  )
}

export default Contact
