import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";  // Import useNavigate
import './Carrier.css';
import People from './Images/young-removebg-preview.png';
import Process from './Images/process.png';
import Technology from './Images/technology.webp';
import Hybrid from './Images/hybrid.jpg';
import Tech from './Images/tech savy.jpg'
import Learning from './Images/learning.png';
import Growth from './Images/growth.jpg';
import AOS from 'aos';
const Carrier =()=>{
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  const navigate = useNavigate(); 
  // 2nd sec
    const [activeTab, setActiveTab] = useState("People");

    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };
    const renderContent = () => {
        switch (activeTab) {
          case "People":
            return {
              imageSrc: People, 
              text: "Our focus on people ensures a collaborative environment where innovation thrives.",
            };
          case "Process":
            return {
              imageSrc: Process, 
              text: "Our streamlined processes enhance efficiency and deliver high-quality results.",
            };
          case "Technology":
            return {
              imageSrc: Technology, 
              text: "Fueled by emerging technologies, we drive high quality and unlock efficiencies.",
            };
          default:
            return {
              imageSrc: "path_to_people_image",
              text: "Our focus on people ensures a collaborative environment where innovation thrives.",
            };
        }
      };
    
      const { imageSrc, text } = renderContent();
      //4th sec
      const [content, setContent] = useState({
        imageUrl: Hybrid,
        text: "In an increasingly borderless environment, Vedanta Info-Tech provides the flexibility of working remotely with the meaningful teaming, collaboration, on-the-job learning, and apprenticeship model culture that comes with co-locating. See what our professionals have to say.",
      });
    
      const handleClick = (newContent) => {
        setContent(newContent);
      };
    

    
return(
<div className="car-main">
    <div className="car-sec1">
        <div className="car-next">
        <div className="halfsec1">
        <div data-aos="fade-right">
        <h1 className="car-heading">Vedanta Info-Tech <span>Career</span></h1>
        <p className="car-sec1-para"> Discover your impact, and open the door to diverse experiences, opportunities, and career paths.</p></div>
        <div className="car-button">
        <button className="car-btn1" onClick={() => navigate('/apply')}>Search Jobs</button></div>
    </div>
    <div className="halfsec2"> </div>
    </div>
   <div className="car-bottom">
   <div data-aos="fade-up">
    <p className="car-para"><span>|</span> Becoming future-ready  <span>|</span> Building a career with Vedanta Info-Tech<span> |</span> Making an impact that matters<span> |</span></p></div>
   </div>
    </div>

    <div className="car-sec2">
    <div >
    <div data-aos="fade-up">
      <h1 className="car-sec2-heading">Becoming Future-Ready</h1>
      <p className="car-sec2-para1">
        At Vedanta Info-Tech, our future-focused culture embraces curiosity, creativity, and collaboration. We leverage the capabilities of our people, processes, and technology to enhance the quality and value we deliver.
      </p></div>

      <div className="car-button-sec2">
        <button onClick={() => handleTabClick("People")} className="car-btn1">People</button>
        <button onClick={() => handleTabClick("Process")}className="car-btn1">Process</button>
        <button onClick={() => handleTabClick("Technology")}className="car-btn1">Technology</button>
      </div>
     
      <div style={{ marginTop: "20px" }} className="car-imgpro-sec2">
        
        <img src={imageSrc} alt={activeTab} className="car-img" />
        <p className="car-para-sec2">{text}</p>
      </div>
    </div>
    </div>


    <div className="car-sec3">
    <div className="meet">
    <div data-aos="fade-up">
    <h1 className="car-sec3-heading">Making Impact that matters</h1></div>
    <div className="flipcard">
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
        
          </div>
          <div class="flip-card-back">
          <div className="cl">
          <h2>Capital Market</h2> </div>
            <p>Driven by our shared purpose—to bring integrity to the capital markets—we maintain the highest quality standards in every aspect of our work.  </p>
          </div>
        </div>
      </div>
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front2">
          
          </div>
          <div class="flip-card-back">
          <div className="cl">
          <h2>Clients</h2> </div>
            <p>We look deeply and objectively into our clients’ businesses to deliver high-quality audit and assurance services while uncovering important insights. We focus on what matters so our clients can too. </p>
          </div>
        </div>
      </div>
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front3">
    
          </div>
          <div class="flip-card-back">
          <div className="cl">
          <h2>Communities</h2> </div>
            <p>By leveraging the breadth of our capabilities and brand, and by targeted investment in social issues, we advance a more equitable economy and make an impact that matters for our clients, people, and communities.  </p>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>


    <div className="car-sec4">
  <div data-aos="fade-up">
    <h1 className="car-sec4-heading">Building Career With Vedanta Info-Tech</h1>
  </div>
  <div className="car-container">
    <div className="car-content">
      <img src={content.imageUrl} alt="Content" className="content-image" />
      <p className="car-sec4-para">{content.text}</p>
    </div>
    <div className="car-sec4-buttons">
      <button
        className="car-sec4change"
        onMouseEnter={() =>
          handleClick({
            imageUrl: Hybrid,
            text: "In an increasingly borderless environment, Vedanta Info-Tech provides the flexibility of working remotely with the meaningful teaming, collaboration, on-the-job learning, and apprenticeship model culture that comes with co-locating. See what our professionals have to say.",
          })
        }
      >
        Hybrid work
      </button>
      <button
        className="car-sec4change"
        onMouseEnter={() =>
          handleClick({
            imageUrl: Learning,
            text: "Vedanta Info-Tech is committed to investing in our professionals and developing them as leaders. Vedanta Info-Tech University is a 700,000+ square foot campus where we do just that, while building confidence, celebrating inclusion and diversity, and fostering a sense of community.",
          })
        }
      >
        Learning & development
      </button>
      <button
        className="car-sec4change"
        onMouseEnter={() =>
          handleClick({
            imageUrl: Tech,
            text: "Our tech-savvy learning pathways help to keep our professionals knowledgeable on the transformative technologies that are most relevant to our clients’ businesses. From cloud, to blockchain, to emerging analytics and robotics, you can stay ahead of the knowledge curve through easily accessible videos, e-learning courses, and Fluent Seas.",
          })
        }
      >
        Tech-savvy learning pathways
      </button>
      <button
        className="car-sec4change"
        onMouseEnter={() =>
          handleClick({
            imageUrl: Growth,
            text: "At all levels of your career, a network of leaders, mentors, and coaches are there to support your development, discuss professional goals, and advocate for your well-being and long-term success. Our match capability technology will facilitate your assignment to a coach focused on supporting your career growth and advancement.",
          })
        }
      >
        Growth through connection
      </button>
    </div>
  </div>
</div>


</div>
);
}
export default Carrier