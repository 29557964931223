import React, { useState } from 'react';
import { toast } from 'react-toastify';

const ApplicationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    position: '',
    education: '',
    experience: '',
    skills: '',
  });
  const [resume, setResume] = useState(null);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.info("Button clicked");
    const data = new FormData();
    
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    
    data.append('resume', resume);

    try {
      const response = await fetch('http://localhost:5001/apply', {
        method: 'POST',
        body: data,
      });

      const result = await response.json();

      if (response.ok ) {
        setMessage('Application submitted successfully!');
        toast.success("Application submitted successfully");
      } else {
        setMessage(result.message || 'An error occurred.');
        toast.error("error");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h2>Job Application Form</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div>
          <label>Phone:</label>
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
        </div>
        <div>
          <label>Address:</label>
          <input type="text" name="address" value={formData.address} onChange={handleChange} required />
        </div>
        <div>
          <label>Position:</label>
          <input type="text" name="position" value={formData.position} onChange={handleChange} required />
        </div>
        <div>
          <label>Education:</label>
          <input type="text" name="education" value={formData.education} onChange={handleChange} required />
        </div>
        <div>
          <label>Experience:</label>
          <input type="text" name="experience" value={formData.experience} onChange={handleChange} required />
        </div>
        <div>
          <label>Skills:</label>
          <input type="text" name="skills" value={formData.skills} onChange={handleChange} required />
        </div>
        <div>
          <label>Resume:</label>
          <input type="file" name="resume" onChange={handleFileChange} required />
        </div>
        <button type="submit">Submit Application</button>
      </form>
    </div>
  );
};

export default ApplicationForm;
