import React, { useEffect } from 'react';
import './Webdev.css';
import { useNavigate } from "react-router-dom";  // Import useNavigate
import AOS from 'aos';
const Webdev=()=>{
   useEffect(() => {
      AOS.init({
        duration: 1200,
      });
    }, []);
    const navigate = useNavigate(); 
    return(
      <div className="web-hero">
      <div className="web-sec1">
          <div className="web-theory">
              <div className="web-text-and-image">
                  <div className="web-hpb">
                  <div data-aos="fade-right">
                      <h1 className="web-head">Web Development</h1>
                      <p className="web-hpb-para">Transform your online presence with our bespoke web development solutions. We create responsive, user-friendly websites that captivate your audience and drive engagement.
 </p>
                      </div>
                      <button className="web-button"onClick={() => navigate('/contact')}>Get Started</button>
                  </div>
                  <div className="web-image"data-aos="fade-left"></div>
              </div>
              </div>
              <div className="web-stats-card">
      <div className="web-stat">
        <div className="icon">&#128101;</div>
        <div className="details">
          <h2>250+</h2>
          <p className='detail-para' >Users</p>
        </div>
      </div>
      <div className="web-stat">
        <div className="icon">&#128205;</div>
        <div className="details">
          <h2>5+</h2>
          <p className='detail-para' >Countries</p>
        </div>
      </div>
      <div className="web-stat">
        <div className="icon">&#127760;</div>
        <div className="details">
          <h2>500+</h2>
          <p className='detail-para' >Consulations</p>
        </div>
      </div>
    </div>   
      </div>
      <div className="web-sec2">
      <div className="web-features-container">
     
      <div className="web-code" data-aos="fade-right">
      </div>
      <div className="web-we-section">
      <div data-aos="fade-left">
        <h2>Our Unique <span className="web-many-features">Features</span> </h2>
         </div>
        <ul>
          <li> Customizable Scalable Solutions</li>
          <li> AI-Driven Personalization</li>
          <li>  Seamless Integration with Emerging Technologies</li>
          <li> Performance Optimization and Speed</li>
        </ul>
       
      </div>
    </div>
      </div>
      <div className="web-sec3">
    
    <div className="web-text-and-image">
        <div className="web-hpb">
        <div data-aos="fade-right">
            <h1 className="web-head1">Our basic Web Development package</h1>
            <p className="web-sec3-para1">At Vedanta Info-Tech, we pride ourselves on offering a range of cutting-edge features to elevate your web development experience. </p>
            </div>
            <button className="web-button1" onClick={() => navigate('/webdev-details')}>Buy Now</button>
          
        </div>
        <div className="web-image1"data-aos="fade-left"></div>
    </div>

    <div className="web-features-container">

<div className="web-code1" data-aos="fade-right">
</div>
<div className="web-we-section">
<div data-aos="fade-left">
<h2>We Provide<span className="web-many-features"> Many Features</span> You Can Use</h2>

</div>
<p className="sec3-paragraph"> Here are some of the powerful tools and services we bring to your projects:</p>
<ul>
  <li>⭐ Scalable and Customizable Solutions</li>
  <li>🚀 AI-Driven Personalization</li>
  <li>💡 Emerging Technologies Integration</li>
  <li>📈 Real-Time Analytics</li>
</ul>



</div>
</div>
    
    
 
</div>

  </div>
  

    );
}
export default Webdev; 