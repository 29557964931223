import React from 'react';
import styled, { keyframes } from 'styled-components';

// Define the animations using keyframes
const swing = keyframes`
  0% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  25% {
    transform: rotate(70deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
`;

const swing2 = keyframes`
  0% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(-70deg);
    animation-timing-function: ease-in;
  }
`;

// Loader Container with full height, fixed position, and centered content
const LoaderContainer = styled.div`
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Styled component for the Newton's Cradle
const CradleContainer = styled.div`
  --uib-size: 50px;
  --uib-speed: 1.2s;
  --uib-color: #474554;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--uib-size);
  height: var(--uib-size);
`;

const CradleDot = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 25%;
  transform-origin: center top;
  &:first-child {
    animation: ${swing} var(--uib-speed) linear infinite;
  }
  &:last-child {
    animation: ${swing2} var(--uib-speed) linear infinite;
  }
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 25%;
    border-radius: 50%;
    background-color: var(--uib-color);
  }
`;

// Main component that renders the Newton's Cradle inside the loader
const Loader = () => {
  return (
    <LoaderContainer>
      <CradleContainer className="newtons-cradle">
        <CradleDot className="newtons-cradle__dot" />
        <CradleDot className="newtons-cradle__dot" />
        <CradleDot className="newtons-cradle__dot" />
        <CradleDot className="newtons-cradle__dot" />
      </CradleContainer>
    </LoaderContainer>
  );
};

export default Loader;
