import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Check if there's a token in localStorage on initial load
        const token = localStorage.getItem('authToken');
        if (token) {
            try {
                const decodedUser = jwtDecode(token);
                setUser(decodedUser); // Store the decoded user data in state
            } catch (error) {
                console.log('Invalid token');
            }
        }
    }, []);

    const loginUser = (token) => {
        // console.log("token:",token);
        
        localStorage.setItem('authToken', token);
        const decodedUser = jwtDecode(token);
        // console.log("decoded Token",decodedUser);
        setUser(decodedUser);
    };

    const logoutUser = () => {
        localStorage.removeItem('authToken');
        setUser(null);
    };

    return (
        <UserContext.Provider value={{ user, loginUser, logoutUser }}>
            {children}
        </UserContext.Provider>
    );
};
