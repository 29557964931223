import React, { useState, useEffect } from "react";
import { toast, Bounce } from 'react-toastify'; // Import Bounce correctly from 'react-toastify'
import './Job.css';
import AOS from 'aos';

function Job() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    city: '',
    state: '',
    country: '',
    pinCode: '',
    education: '',
    skills: '',
    experience: '',
  });

  const [resume, setResume] = useState(null);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.info("Button clicked", { transition: Bounce }); // Use Bounce correctly as a transition

    if (!resume) {
      toast.error("Please upload a resume.", { transition: Bounce });
      return;
    }

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    data.append('resume', resume);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/apply`, {
        method: 'POST',
        body: data,
      });

      const result = await response.json();

      if (response.ok) {
        setMessage('Application submitted successfully!');
        toast.success("Application submitted successfully", { transition: Bounce });
      } else {
        setMessage(result.message || 'An error occurred.');
        toast.error(result.message || "An error occurred.", { transition: Bounce });
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      toast.error(`Error: ${error.message}`, { transition: Bounce });
    }
  };

  return (
    <div className="job-container">
       <form onSubmit={handleSubmit}></form>
      <div data-aos="fade-down">
        <h1 className="job-heading">Application Process</h1>
      </div>
      <form onSubmit={handleSubmit} className="job-form">
        <div data-aos="fade-up">
          <div className="job-content">
            <label>First Name</label>
            <input
              className="job-input"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="job-content">
            <label>Last Name</label>
            <input
              className="job-input"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="job-content">
            <label>Email</label>
            <input
              className="job-input"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="job-content">
            <label>Phone Number</label>
            <input
              className="job-input"
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div className="job-content">
            <label>City</label>
            <input
              className="job-input"
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
          <div className="job-content">
            <label>State</label>
            <input
              className="job-input"
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
          </div>
          <div className="job-content">
            <label>Country</label>
            <input
              className="job-input"
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
            />
          </div>
          <div className="job-content">
            <label>Pin Code</label>
            <input
              className="job-input"
              type="text"
              name="pinCode"
              value={formData.pinCode}
              onChange={handleChange}
            />
          </div>
          <div className="job-content">
            <label>Education</label>
            <textarea
              className="job-textarea"
              name="education"
              value={formData.education}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="job-content">
            <label>Skills</label>
            <textarea
              className="job-textarea"
              name="skills"
              value={formData.skills}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="job-content">
            <label>Experience</label>
            <textarea
              className="job-textarea"
              name="experience"
              value={formData.experience}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="job-content">
            <label>Upload Resume/CV (Upload in png/jpg/jpeg only)</label>
            <input type="file" name="resume" onChange={handleFileChange} className="resume" />
          </div>
          <button type="submit" className="job-button">Submit</button>
        </div>
      </form>
    </div>
  );
}

export default Job;
