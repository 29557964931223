import React, { useState } from 'react';
import './Ani-Details.css'; 
import Detailimg from '../Images/4884421.jpg'
const Details = () => {
  const [quantity, setQuantity] = useState(1);
  const [duration, setDuration] = useState('3 months');
  const [price] = useState(40000);
  const [sessions] = useState(12);

  const handleQuantityChange = (type) => {
    if (type === 'increase') {
      setQuantity(quantity + 1);
    } else if (type === 'decrease' && quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    
    <div className="product-detail-container">

      <div className="product-image-section">
        <img src={Detailimg} alt="Individual Counselling" className="product-image" />
      </div>
      <div className="product-info-section">
        <h1 className="product-info-heading">Video Animations</h1>
        <hr className="product-hr"></hr>
        <p className="product-price">Rs {price.toLocaleString()}</p>
        <hr className="product-hr"></hr>
        <div className="quantity-section">
          <button className="quantity-button" onClick={() => handleQuantityChange('decrease')}>-</button>
          <span className="quantity-display">{quantity}</span>
          <button className="quantity-button" onClick={() => handleQuantityChange('increase')}>+</button>
          {/* <button className="reset-button">⟳</button> */}
          <button className="add-to-cart-button">Add to Cart</button>
  
        </div>
    
        <hr className="product-hr"></hr>
        <button className="buy-button">Buy</button>

        {/* <div className="duration-options">
          <button className={`duration-button ${duration === '3 months' ? 'active' : ''}`} onClick={() => setDuration('3 months')}>3 months</button>
          <button className={`duration-button ${duration === '6 months' ? 'active' : ''}`} onClick={() => setDuration('6 months')}>6 months</button>
          <button className={`duration-button ${duration === '12 months' ? 'active' : ''}`} onClick={() => setDuration('12 months')}>12 months</button>
          <button className={`duration-button ${duration === '0 months' ? 'active' : ''}`} onClick={() => setDuration('0 months')}>0 months</button>
        </div> */}

        {/* <p>No of Sessions: {sessions}</p> */}
      </div>
    </div>


  );
};

export default Details;
