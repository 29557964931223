import React, { useEffect } from 'react';
import './MobileApp.css';
import { useNavigate } from "react-router-dom";  // Import useNavigate
import AOS from 'aos';
const MobileApp=()=>{
   useEffect(() => {
      AOS.init({
        duration: 1200,
      });
    }, []);
    const navigate = useNavigate(); 
    return(
      <div class="mobile-hero">
      <div class="mobilesec1">
          <div class="mobile-theory">
              <div class="mobile-text-and-image">
                  <div className="mobile-hpb">
                  <div data-aos="fade-right">
                      <h1 class="mobile-head">Mobile App</h1>
                      <p className="mobile-hpb-para">Unlock the full potential of your business with our custom application development services. From mobile apps to enterprise solutions, we develop applications that meet your specific requirements.
 </p>
                      </div>
                      <button className="mobile-button"onClick={() => navigate('/contact')}>Get Started</button>
                  </div>
                  <div class="mobile-image"data-aos="fade-left"></div>
              </div>
              </div>
              <div className="mobile-stats-card">
      <div className="mobile-stat">
        <div className="icon">&#128101;</div>
        <div className="details">
          <h2>250+</h2>
          <p>Users</p>
        </div>
      </div>
      <div className="mobile-stat">
        <div className="icon">&#128205;</div>
        <div className="details">
          <h2>5+</h2>
          <p>Locations</p>
        </div>
      </div>
      <div className="mobile-stat">
      <div className="icon">&#127760;</div>
        <div className="details">
          <h2>500+</h2>
          <p >Consulation</p>
        </div>
      </div>
    </div>   
      </div>
      <div className="mobile-sec2">
      <div className="mobile-features-container">
     
      <div className="mobile-code" data-aos="fade-right">
      </div>
      <div className="mobile-we-section">
      <div data-aos="fade-left">
        <h2>Our Unique<span className="mobile-many-features"> Features</span> </h2>
         </div>
        <ul>
          <li> Cross-Platform Development</li>
          <li>   Progressive Web Apps (PWA)</li>
          <li>  AI & Machine Learning Integration</li>
          <li>AR & VR Capabilities</li>
        </ul>
       
      </div>
    </div>
      </div>
      <div className="mobile-sec3">
    
    <div className="mobile-text-and-image">
        <div className="mobile-hpb">
        <div data-aos="fade-right">
            <h1 className="mobile-head1">Our basic mobile development package</h1>
            <p className="mobile-sec3-para1">At Vedanta Info-Tech, we offer a comprehensive suite of features to help you build cutting-edge mobile apps that deliver superior user experiences and robust performance.</p>
            </div>
            <button className="mobile-button1" onClick={() => navigate('/mobile-details')}>Buy Now</button>
          
        </div>
        <div className="mobile-image1"data-aos="fade-left"></div>
    </div>

    <div className="mobile-features-container">

<div className="mobile-code1" data-aos="fade-right">
</div>
<div className="mobile-we-section">
<div data-aos="fade-left">
<h2>We Provide<span className="mobile-many-features"> Many Features</span> You Can Use</h2>

</div>
<p className="sec3-paragraph">Here are just a few of the features we bring to your mobile development project:

</p>
<ul>
  <li>📱 Cross-Platform Development
</li>
  <li>🚀 Progressive Web Apps (PWA)</li>
  <li>🧠 AI & Machine Learning Integration</li>
  <li>🌐 Offline Functionality</li>
</ul>


</div>
</div>
 
    
 
</div>

  </div>
  

    );
}
export default MobileApp; 