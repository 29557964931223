import React, { useEffect } from 'react';
import './Software.css';
import { useNavigate } from "react-router-dom";  // Import useNavigate
import AOS from 'aos';
const Software=()=>{
   useEffect(() => {
      AOS.init({
        duration: 1200,
      });
    }, []);
    const navigate = useNavigate(); 
    return(
      <div className="soft-hero">
      <div className="soft-sec1">
          <div className="soft-theory">
              <div className="soft-text-and-image">
                  <div className="soft-hpb">
                  <div data-aos="fade-right">
                      <h1 className="soft-head">Softwares</h1>
                      <p className="soft-hpb-para">Ensure your software operates seamlessly with our comprehensive support services. We provide ongoing maintenance, troubleshooting, and upgrades to keep your systems running smoothly.
</p>
                      </div>
                      <button className="soft-button"onClick={() => navigate('/contact')}>Get Started</button>
                  </div>
                  <div className="soft-image"data-aos="fade-left"></div>
              </div>
              </div>
              <div className="soft-stats-card">
      <div className="soft-stat">
      <div className="icon">&#128101;</div>
        <div className="details">
          <h2>250+</h2>
          <p>Users</p>
        </div>
      </div>
      <div className="soft-stat">
        <div className="icon">&#128205;</div>
        <div className="details">
          <h2>5+</h2>
          <p>Locations</p>
        </div>
      </div>
      <div className="soft-stat">
      <div className="icon">&#127760;</div>
        <div className="details">
          <h2>500+</h2>
          <p >Consulation</p>
        </div>
      </div>
    </div>   
      </div>
      <div className="soft-sec2">
      <div className="soft-features-container">
     
      <div className="soft-code" data-aos="fade-right">
      </div>
      <div className="soft-we-section">
      <div data-aos="fade-left">
        <h2>Our Unique<span className="soft-many-features"> Features</span> </h2>
         </div>
        <ul>
          <li> Custom-Built Solutions</li>
          <li> Agile Development Methodology</li>
          <li> Cloud-Native Architecture</li>
          <li> AI and Machine Learning Integration</li>
        </ul>
       
      </div>
    </div>
      </div>
      <div className="soft-sec3">
    
    <div className="soft-text-and-image">
        <div className="soft-hpb">
        <div data-aos="fade-right">
            <h1 className="soft-head1">Our basic Software development package</h1>
            <p className="soft-sec3-para1">At Vedanta Info-Tech, we offer a diverse range of features designed to enhance your business operations and drive innovation </p>
            </div>
            <button className="soft-button1" onClick={() => navigate('/software-details')}>Buy Now</button>
        
        </div>
        <div className="soft-image1"data-aos="fade-left"></div>
    </div>

    <div className="soft-features-container">

<div className="soft-code1" data-aos="fade-right">
</div>
<div className="soft-we-section">
<div data-aos="fade-left">
<h2>We Provide<span className="soft-many-features"> Many Features</span> You Can Use</h2>

</div>
<p className="sec3-paragraph">Explore the capabilities we provide: </p>
<ul>
  <li>🔍 Advanced Analytics & Reporting</li>
  <li>🌐 Cloud Integration</li>
  <li>🛠️ Custom Software Development</li>
  <li>🧠 AI & Machine Learning</li>
</ul>
           

</div>
</div>
   
    
 
</div>


  </div>
  

    );
}
export default Software; 