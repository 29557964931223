import React, { useEffect } from 'react';
import './Ecommerce.css';
import { useNavigate } from "react-router-dom";  // Import useNavigate
import AOS from 'aos';
const Ecom=()=>{
   useEffect(() => {
      AOS.init({
        duration: 1200,
      });
    }, []);
    const navigate = useNavigate(); 
    return(
      <div className="ecom-hero">
      <div className="ecom-sec1">
          <div className="eco-theory">
              <div className="ecom-text-and-image">
                  <div className="ecom-hpb">
                  <div data-aos="fade-right">
                      <h1 className="ecom-head">E-Commerce</h1>
                      <p className="ecom-hpb-para">Enhance your customer relationships with our robust CRM systems. We design and implement CRMs that streamline your operations and improve customer satisfaction.
</p>
                      </div>
                      <button className="ecom-button"onClick={() => navigate('/contact')}>Get Started</button>
                  </div>
                  <div className="ecom-image"data-aos="fade-left"></div>
              </div>
              </div>
              <div className="ecom-stats-card">
      <div className="ecom-stat">
        <div className="icon">&#128101;</div>
        <div className="details">
          <h2>250+</h2>
          <p>Users</p>
        </div>
      </div>
      <div className="ecom-stat">
        <div className="icon">&#128205;</div>
        <div className="details">
          <h2>5+</h2>
          <p>Locations</p>
        </div>
      </div>
      <div className="ecom-stat">
      <div className="icon">&#127760;</div>
        <div className="details">
          <h2>500+</h2>
          <p >Consulation</p>
        </div>
      </div>
    </div>   
      </div>
      <div className="ecom-sec2">
      <div className="ecom-features-container">
     
      <div className="ecom-code" data-aos="fade-right">
      </div>
      <div className="ecom-we-section">
      <div data-aos="fade-left">
        <h2>Our Unique<span className="ecom-many-features">  Features</span></h2>
         </div>
        <ul>
          <li> AI-Powered Product Recommendations</li>
          <li> Seamless Multi-Channel Integration</li>
          <li> Augmented Reality (AR) for Product Visualization</li>
          <li> Dynamic Pricing Engine</li>
        </ul>
       
      </div>
    </div>
      </div>
      <div className="ecom-sec3">
    
              <div class="ecom-text-and-image">
                  <div className="ecom-hpb">
                  <div data-aos="fade-right">
                      <h1 class="ecom-head1">Our basic E-Commerce package</h1>
                      <p className="ecom-sec3-para1">At Vedanta Info-Tech, we offer a wide array of innovative features designed to help your eCommerce business thrive in today’s competitive market.  </p>
                      </div>
                      <button className="ecom-button1"  onClick={() => navigate('/ecom-details')}>Buy Now</button>
                      {/* <button className="ecom-button2">$100</button> */}
                  </div>
                  <div class="ecom-image1"data-aos="fade-left"></div>
              </div>

              <div className="ecom-features-container">
     
     <div className="ecom-code1" data-aos="fade-right">
     </div>
     <div className="ecom-we-section">
     <div data-aos="fade-left">
     <h2>We Provide<span className="ecom-many-features"> Many Features</span> You Can Use</h2>
     
        </div>
        <p className="sec3-paragraph">Explore some of the cutting-edge features we provide: </p>
        <ul>
          <li>⭐ AI-Powered Product Recommendations
</li>
          <li>🚀 Seamless Multi-Channel Integration

</li>
          <li>🔍 Advanced Product Search and Filters
</li>
          <li>📱 Mobile-First & Progressive Web Apps (PWA)
</li>
        </ul>
       
      
     </div>
   </div>
   </div>
   </div>
             
    );
}
export default Ecom; 