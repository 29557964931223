import React, { useEffect  } from 'react';
import './Animation.css';
import { useNavigate } from "react-router-dom";  // Import useNavigate
import AOS from 'aos';
const Animation=()=>{
   useEffect(() => {
      AOS.init({
        duration: 1200,
      });
    }, []);
    const navigate = useNavigate(); 
    return(
      <div className="ani-hero">
      <div className="ani-sec1">
          <div className="ani-theory">
              <div className="ani-text-and-image">
                  <div className="ani-hpb">
                  <div data-aos="fade-right">
                      <h1 className="ani-head">Animations</h1>
                      <p className="ani-hpb-para">Engage and inspire your audience with our creative animational videos. Our team produces captivating animations that effectively communicate your message and brand story.
 </p>
                      </div>
                      <button className="ani-button"onClick={() => navigate('/contact')}>Get Started</button>
                  </div>
                  <div className="ani-image"data-aos="fade-left"></div>
              </div>
              </div>
              <div className="ani-stats-card">
      <div className="ani-stat">
      <div className="icon">&#128101;</div>
        <div className="details">
          <h2>250+</h2>
          <p>Users</p>
        </div>
      </div>
      <div className="soft-stat">
        <div className="icon">&#128205;</div>
        <div className="details">
          <h2>5+</h2>
          <p>Locations</p>
        </div>
      </div>
      <div className="soft-stat">
      <div className="icon">&#127760;</div>
        <div className="details">
          <h2>500+</h2>
          <p >Consulation</p>
        </div>
      </div>
    </div>   
      </div>
      <div className="ani-sec2">
      <div className="ani-features-container">
     
      <div className="ani-code" data-aos="fade-right">
      </div>
      <div className="ani-we-section">
      <div data-aos="fade-left">
        <h2>We Provide<span className="ani-many-features"> Many Features</span> You Can Use</h2>
         </div>
        <ul>
          <li>  Custom 2D and 3D Animation</li>
          <li>  Interactive Animations</li>
          <li> High-Quality Motion Graphics</li>
          <li> Infographic Animations</li>
        </ul>
       
      </div>
    </div>
      </div>
      <div className="ani-sec3">
    
    <div className="ani-text-and-image">
        <div className="ani-hpb">
        <div data-aos="fade-right">
            <h1 className="ani-head1">Our basic Animation package</h1>
            <p className="ani-sec3-para1">At Vedanta Info-Tech, we offer a wide array of features designed to enhance your projects and drive success across different domains.</p>
            </div>
            <button className="ani-button1"  onClick={() => navigate('/ani-details')}>Buy Now</button>

        </div>
        <div className="ani-image1"data-aos="fade-left"></div>
    </div>

    <div className="ani-features-container">

<div className="ani-code1" data-aos="fade-right">
</div>
<div className="ani-we-section">
<div data-aos="fade-left">
<h2>We Provide<span className="ani-many-features"> Many Features</span> You Can Use</h2>

</div>
<p className="sec3-paragraph">Explore the capabilities we provide:</p>
<ul>
  <li>💻 Cutting-Edge Technology Integration</li>
  <li>📱 Cross-Platform Development</li>
  <li>🌐 Cloud Solutions</li>
  <li>🔐 Advanced Security Measures</li>
</ul>


</div>
</div>
  
    
 
</div>


</div>

  

    );
}
export default Animation; 