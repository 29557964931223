import React from 'react';
import './T&C.css';
function TC() {
  return (
    <div>
      <h1 className='tc-h1'>Terms and Conditions</h1>
        <h3 className='tc'>VISITORS TO THIS WEBSITE ARE BOUND BY THE FOLLOWING TERMS AND CONDITIONS (“TERMS”). SO, PLEASE READ THE TERMS CAREFULLY BEFORE CONTINUING TO USE THIS SITE. IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, PLEASE DO NOT USE THIS SITE.</h3>
        <p className='tc-para'>The use of this website is subject to the following terms of use:</p>
<ul className='tc-ul'>
    <li className='tc-li'>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
    <br/>
    <li className='tc-li'>This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, personal information may be stored by us for use by third parties.
</li>
<br/>
<li className='tc-li'>Neither Vedanta InfoTech nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
    <br/>
    <li className='tc-li'>Your use of any information or materials on this website is entirely at your own risk, for which Vedanta InfoTech shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.
</li>
<br/>
<li className='tc-li'>This website contains material that is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics.
</li>
<br/>
<li className='tc-li'>Some photographs are here used from the website <a>www.freepik.com</a>, so here by giving the attribution</li>
    <br/>
    <li className='tc-li'>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that Vedanta InfoTech endorses the website(s). Vedanta InfoTech has no responsibility for the content of the linked website(s).
</li>
<br/>
</ul>
      
    </div>
  )
}

export default TC
