import React, { useState,useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import './LoginModal.css';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
import axios from 'axios';

const LoginModal = ({ isOpen, onClose }) => {
    const { loginUser } = useContext(UserContext);

    const [rightPanelActive, setRightPanelActive] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const handleRegisterClick = () => {
        setRightPanelActive(true);
    };

    const handleLoginClick = () => {
        setRightPanelActive(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (
            formData.name.trim() === "" || 
            formData.password.trim() === "" ||
            formData.email.trim() === "" ||
            formData.confirmPassword.trim() === ""
        ) {
            toast.warn("Please fill all the fields");
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            toast.warn("Passwords do not match");
            return;
        }

        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/auth/signin`, {
                name: formData.name,
                email: formData.email,
                password: formData.password
            });
            
            toast.success("Registered successfully");
            handleLoginClick(); // Switch to login form after successful registration
        } catch (error) {
            toast.error("Registration failed");
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (formData.email.trim() === "" || formData.password.trim() === "") {
            toast.warn("Please fill all the fields");
            return;
        }
    
        setLoading(true);
        try {
            // Send the login request
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                email: formData.email,
                password: formData.password
            });
            // console.log(response);
            
            // Check if the response status is 200 (OK) and if the token is present
            if (response.status === 200 && response.data.token) {
                const { token } = response.data;
    
                // Store the token in localStorage or sessionStorage
                // localStorage.setItem('authToken', token);
                
                // Store user data using UserContext
                loginUser(token);

                toast.success("Logged in successfully");
            } else {
                // Handle cases where the token is not returned or status is not 200
                toast.error("Login failed: Invalid response from server");
            }
        } catch (error) {
            // Check for specific error statuses and respond accordingly
            if (error.response && error.response.status === 401) {
                toast.error("Invalid credentials");
            } else if (error.response && error.response.status === 404) {
                toast.error("User not found");
            } else {
                toast.error("Login failed");
            }
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className={`main-container ${rightPanelActive ? 'right-panel-active' : ''}`} id="container">
                <div className="form-container register-container">
                    <form onSubmit={handleRegister}>
                        <h1 className="headinglogin">Register</h1>
                        <input 
                            type="text" 
                            placeholder="Name" 
                            name="name" 
                            value={formData.name} 
                            onChange={handleChange}
                        />
                        <input 
                            type="email" 
                            placeholder="Email" 
                            name="email" 
                            value={formData.email} 
                            onChange={handleChange}
                        />
                        <input 
                            type="password" 
                            placeholder="Password" 
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                        />
                        <input 
                            type="password" 
                            placeholder="Confirm Password" 
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                        />
                        <button type="submit" className='login-button'>Register</button>
                    </form>
                </div>

                <div className="form-container login-container">
                    <form onSubmit={handleLogin}>
                        <h1 className="headinglogin">Login</h1>
                        <input 
                            type="email" 
                            placeholder="Email" 
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        <input 
                            type="password" 
                            placeholder="Password" 
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                        />
                        <button type="submit" className="login-button">Login</button>
                    </form>
                </div>

                <div className="overlay-container">
                    <div className="overlay">
                        <div className="overlay-panel overlay-left">
                            <h1 className="welcometitle">Welcome <br /> Back!</h1>
                            <p className="loginpara">If you have an account, login here and have fun</p>
                            <button className="loginghost" id="login" onClick={handleLoginClick}>
                                Login <i className="lni lni-arrow-left login"></i>
                            </button>
                        </div>
                        <div className="overlay-panel overlay-right">
                            <h1 className="title">Start your <br /> journey now</h1>
                            <p className="registerpara">If you don't have an account yet, join us and start your journey.</p>
                            <button className="registerghost" id="register" onClick={handleRegisterClick}>
                                Register <i className="lni lni-arrow-right register"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <Loader />} {/* Show loader while loading */}
            <span className="close-modal" onClick={onClose}>&times;</span>
        </div>
    );
};

export default LoginModal;
